import * as React from "react";
import Seo from "../../../components/seo";
import Layout from "../../../components/layout";
import ArtBoard from "../../../components/ArtBoard";
import Pikachu from "../../../components/cssart/pikachu";

const CssArt = () => {
  return (
    <Layout pageTitle="CSS Art: Pikachu">
      <Seo title="Pikachu - CSS ART" description="Pikachu on CSS" />
      <ArtBoard
        bgcolor="#ffe4e4"
        link="https://codepen.io/amayadoring/pen/yLooxbp"
      >
        <Pikachu />
      </ArtBoard>
    </Layout>
  );
};

export default CssArt;
